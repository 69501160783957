import React from "react"

import Section from "../../Elements/Section"
import DispensingChannelOptions from "./DispensingChannelOptions"

const DispensingChannelOptionsSection = ({
  values,
  isNationwide,
  setFieldValue,
  configurationChannels,
  module,
}) => {
  return (
    <Section title="Dispensing Channel Options" className="mt-1">
      <DispensingChannelOptions
        values={values}
        setFieldValue={setFieldValue}
        isNationwide={isNationwide}
        configurationChannels={configurationChannels}
        module={module}
      />
    </Section>
  )
}

export default DispensingChannelOptionsSection
