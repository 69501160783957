import React, { Fragment, useContext } from "react"
import classNames from "classnames"
import Img from "gatsby-image"

import { AppContext } from "../../../context/AppContext"
import styles from "../utils/epharmacy.module.scss"

import FormSelect from "../../Elements/Form/FormSelect"
import PaymentOptionFollowUpField from "./PaymentOptionFollowUpField"
import RadioContainerButton from "./RadioContainerButton"

import {
  accumulatePrice,
  generateDeliveryFee,
  formatPrice,
} from "../services/computations"
import { getCouponDiscountAmount } from "../services/coupon"

import regularOrderCourierOptions from "../utils/regularOrderCourierOptions.json"

const PaymentOptions = ({
  values,
  setFieldValue,
  data,
  module,
  isNationwide,
  courierOption,
}) => {
  const { state } = useContext(AppContext)
  const { epharmacy } = state
  const province = epharmacy?.deliveryAddress?.province.value
  const city = epharmacy?.deliveryAddress?.city.value
  const { medicines, patients, coupon } = epharmacy
  const medicineSKUs =
    patients?.length > 0
      ? patients
          ?.reduce(
            (allMedicines, currentPatient) =>
              allMedicines.concat(currentPatient?.medicines),
            []
          )
          ?.map((medicine) => medicine?.productTitle)
      : medicines?.map((medicine) => medicine?.productTitle)

  const specialOrderMedicines = patients?.reduce(
    (allMedicines, currentPatient) =>
      allMedicines.concat(currentPatient?.medicines),
    []
  )

  const allMedicines =
    module === "Special Order" ? specialOrderMedicines : medicines

  let couponDiscount = getCouponDiscountAmount(
    coupon,
    allMedicines,
    generateDeliveryFee()
  )

  const orderSubtotal = formatPrice({
    priceString: (
      accumulatePrice(allMedicines) +
      generateDeliveryFee() -
      couponDiscount
    ).toFixed(2),
  })

  let cleanedSubtotal = orderSubtotal.replace(/,/g, "")

  let courierOptionInfo = regularOrderCourierOptions.find(
    (option) => option.value == courierOption
  )

  let paymentOptionValues = courierOptionInfo?.paymentOptions.filter(
    (option) => {
      if (option?.includedProvinces) {
        if (option?.includedProvinces.includes(province)) return option
        else return null
      } else {
        return option
      }
    }
  )

  let paymentOptionFollowUpField = courierOptionInfo?.paymentOptionFollowUp.find(
    (followUp) => followUp.value == values?.paymentOption?.value
  )?.field

  if (values?.courierOption)
    return (
      <div className="mt-1">
        {courierOption?.paymentOptions?.map((paymentOption) => {
          let hasExcludedMeds = paymentOption?.exclusions?.medicines?.filter(
            (exclude) => medicineSKUs.includes(exclude)
          )

          if (
            (isNationwide && !paymentOption.isNationwide) ||
            hasExcludedMeds?.length > 0
          )
            return null
          if (
            courierOption?.name === "medgrocer" &&
            paymentOption?.label === "Cash on Delivery" &&
            parseFloat(cleanedSubtotal) > 100000
          ) {
            return null
          }
          if (
            paymentOption?.inclusions?.city?.[province]?.length > 0 &&
            !paymentOption?.inclusions?.city?.[province]?.includes(city)
          ) {
            return null
          }

          if (
            paymentOption?.inclusions?.province?.length > 0 &&
            !paymentOption?.inclusions?.province.includes(province)
          )
            return null

          return (
            <Fragment>
              <div
                className={classNames(
                  styles[
                    `shippingDetails__paymentOption${
                      values.paymentOption === paymentOption.label
                        ? "Active"
                        : ""
                    }`
                  ]
                )}
                onClick={() => {
                  setFieldValue("paymentOption", paymentOption.label)
                }}
              >
                <div
                  className={classNames(
                    "columns is-vcentered is-mobile ml-1 ml-0-mobile",
                    styles["shippingDetails__paymentOptionContainer"]
                  )}
                >
                  <RadioContainerButton
                    isSelected={values.paymentOption === paymentOption.label}
                  />
                  <div className="column is-1 mx-1 mr-3-mobile ml-1-mobile">
                    <Img
                      fixed={data[paymentOption.imgName]?.childImageSharp.fixed}
                    />
                  </div>
                  <div className="column">
                    <p className="is-size-6 has-text-left">
                      {paymentOption.label}
                    </p>
                    {!!paymentOption?.helper ? (
                      <p className="help has-text-left">
                        {paymentOption.helper}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </Fragment>
          )
        })}
        <FormSelect
          isRequired
          name={"paymentOption"}
          placeholder={"Bank Transfer/Deposit"}
          label={"Payment Option"}
          value={values?.paymentOption}
          options={paymentOptionValues}
        />
        {paymentOptionFollowUpField && (
          <PaymentOptionFollowUpField
            paymentOptionFollowUpField={paymentOptionFollowUpField}
          />
        )}
      </div>
    )
  else return null
}

export default PaymentOptions
