import React, { Fragment } from "react"
import classNames from "classnames"

import CourierOption from "./CourierOption"

import styles from "../utils/epharmacy.module.scss"

import {
  faLocationDot,
  faTruck,
  faMapPin,
  faHourglassHalf,
  faPesoSign,
} from "@fortawesome/free-solid-svg-icons"

const icons = {
  faLocationDot,
  faTruck,
  faMapPin,
  faHourglassHalf,
  faPesoSign,
}

const CourierOptions = ({
  values,
  setFieldValue,
  shippingOptionsImages,
  cardInfo,
  filteredCourierOptions,
}) => {
  return (
    <Fragment>
      <label className={classNames("label has-text-weight-bold mt-1")}>
        Choose your preferred sub-distributor
      </label>
      <label className="has-text-grey">
        Select MedGrocer to avail of the free nationwide delivery offer
      </label>
      <div className={classNames(styles["shippingDetails__shippingOptions"])}>
        {filteredCourierOptions.map((courierOption) => {
          return (
            <CourierOption
              setFieldValue={setFieldValue}
              values={values}
              courierOption={courierOption}
              shippingOptionsImages={shippingOptionsImages}
              cardInfo={cardInfo}
            />
          )
        })}
      </div>
    </Fragment>
  )
}

export default CourierOptions
