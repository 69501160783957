import React, { Fragment } from "react"
import classNames from "classnames"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLocationDot } from "@fortawesome/free-solid-svg-icons"
import styles from "../utils/epharmacy.module.scss"

const CourierUserAddress = ({ province, city, barangay }) => {
  if (province && city && barangay)
    return (
      <Fragment>
        <label className={classNames("label has-text-weight-bold mt-1")}>
          Your address
        </label>
        <div className={classNames(styles["shippingDetails__address"])}>
          <div className={classNames(styles["shippingDetails__addressIcon"])}>
            <FontAwesomeIcon icon={faLocationDot} size="2x" />
          </div>
          <div className={classNames(styles["shippingDetails__addressText"])}>
            <p
              className={classNames(
                styles["shippingDetails__addressTextPrimary"]
              )}
            >
              {city}, {barangay}
            </p>
            <p className="has-text-weight-normal has-text-grey is-size-6">
              {province}
            </p>
          </div>
        </div>
      </Fragment>
    )
  else return null
}

export default CourierUserAddress
