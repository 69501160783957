import { useStaticQuery, graphql } from "gatsby"

const ALL_SHIPPING_OPTIONS_IMAGES = graphql`
  {
    cod: file(relativePath: { eq: "payment-details/payment_cod.png" }) {
      childImageSharp {
        fixed(width: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    cod_big: file(relativePath: { eq: "payment-details/payment_cod.png" }) {
      childImageSharp {
        fixed(width: 70) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    bank_brand: file(
      relativePath: { eq: "payment-details/payment_bank--square.png" }
    ) {
      childImageSharp {
        fixed(width: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    bank: file(
      relativePath: { eq: "payment-details/payment_bank-transfer.png" }
    ) {
      childImageSharp {
        fixed(width: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    bank_big: file(
      relativePath: { eq: "payment-details/payment_bank-transfer.png" }
    ) {
      childImageSharp {
        fixed(width: 70) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    gcash: file(
      relativePath: { eq: "payment-details/payment_gcash--square.png" }
    ) {
      childImageSharp {
        fixed(width: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    check: file(
      relativePath: { eq: "payment-details/payment_cheque--square.png" }
    ) {
      childImageSharp {
        fixed(width: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    card: file(
      relativePath: { eq: "payment-details/payment_card--square.png" }
    ) {
      childImageSharp {
        fixed(width: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    card_big: file(
      relativePath: { eq: "payment-details/payment_card--square.png" }
    ) {
      childImageSharp {
        fixed(width: 70) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    card_brand: file(
      relativePath: { eq: "payment-details/payment_card--square-brand.png" }
    ) {
      childImageSharp {
        fixed(width: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    medgrocer: file(
      relativePath: { eq: "payment-details/shipping_mg--square.png" }
    ) {
      childImageSharp {
        fixed(width: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    medgrocer_wordmark: file(
      relativePath: { eq: "payment-details/shipping_mg--wordmark.png" }
    ) {
      childImageSharp {
        fixed(height: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    clermont: file(
      relativePath: { eq: "payment-details/shipping_clermont--square.png" }
    ) {
      childImageSharp {
        fixed(width: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    gb: file(relativePath: { eq: "payment-details/shipping_gb--square.png" }) {
      childImageSharp {
        fixed(width: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    globo: file(
      relativePath: { eq: "payment-details/shipping_globo--square.png" }
    ) {
      childImageSharp {
        fixed(width: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    globo_wordmark: file(
      relativePath: { eq: "payment-details/shipping_globo--wordmark.png" }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    phoenagon: file(
      relativePath: { eq: "payment-details/shipping_all--Phoenagon.png" }
    ) {
      childImageSharp {
        fixed(width: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    medexpress: file(
      relativePath: { eq: "payment-details/shipping_medexpress--square.png" }
    ) {
      childImageSharp {
        fixed(width: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export const useShippingOptionsImages = () => {
  const data = useStaticQuery(ALL_SHIPPING_OPTIONS_IMAGES)

  return data
}
