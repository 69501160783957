import React from "react"
import Layout from "../../components/Layout/Layout"
import Container from "../../components/Layout/Container"
import PaymentDetails from "../../components/Epharmacy/PaymentDetails"

export default () => (
  <Layout
    title="Payment and Shipping Details"
    subtitle="Select your preferred dispensing channel and payment method."
    process="epharmacy"
    seoTitle="Payment and Shipping Details"
    display={{ footer: false, helpCenterBanner: false }}
    underMaintenance={
      process.env.GATSBY_HAS_SPECIAL_ORDER_ENABLED === "disabled"
    }
  >
    <Container isCentered desktop={6} fullhd={6}>
      <PaymentDetails
        backRoute={"/special-order/delivery-details"}
        baseRoute="/special-order"
        module="Special Order"
      />
    </Container>
  </Layout>
)
