const MEDEXPRESS_OPTION = "medexpress"

const filterCourierOptions = ({
  isNationwide,
  medicineSKUs,
  province,
  configurationChannels,
  city,
  courierOptions,
}) => {
  return courierOptions.filter((courierOption) => {
    let productLocations =
      Object.keys(courierOption?.inclusions?.productLocations || {}) || []

    let includedMedicines =
      courierOption?.inclusions?.products?.find?.(
        (product) => product?.isNationwide === isNationwide
      )?.medicines || []
    let filteredIncludedMeds = medicineSKUs?.filter((medicine) =>
      includedMedicines?.length > 0
        ? !includedMedicines?.includes(medicine)
        : false
    )
    let excludedMeds =
      medicineSKUs?.filter((medicine) =>
        courierOption?.exclusions?.products?.includes(medicine)
      ) || []

    if (productLocations?.length > 0) {
      let includedInProductLocations = productLocations?.filter(
        (medicine) => !!medicineSKUs?.includes(medicine)
      )

      let productNotCoveredInLocation = includedInProductLocations?.filter(
        (productLocationMedicine) => {
          return !courierOption?.inclusions?.productLocations?.[
            productLocationMedicine
          ]?.province?.includes(province)
        }
      )
      if (productNotCoveredInLocation?.length > 0) return false
    }

    if (excludedMeds?.length > 0) return false
    if (filteredIncludedMeds?.length > 0) return false
    if (courierOption?.name === MEDEXPRESS_OPTION) return false
    if (
      isNationwide &&
      !courierOption.isNationwide &&
      !courierOption?.inclusions?.province
    )
      return false
    if (courierOption?.exclusions?.province?.includes?.(province)) return false
    if (
      configurationChannels?.length > 0 &&
      !configurationChannels.includes(courierOption?.name)
    )
      return false

    if (
      courierOption?.inclusions?.city?.[province]?.length > 0 &&
      !courierOption?.inclusions?.city?.[province]?.includes(city)
    ) {
      return false
    }

    if (
      courierOption?.inclusions?.province?.length > 0 &&
      !courierOption?.inclusions?.province.includes(province)
    )
      return false
    return true
  })
}

export default filterCourierOptions
