import React, { useContext, useEffect } from "react"
import { isEqual } from "lodash"

import CourierUserAddress from "./CourierUserAddress"
import CourierOptions from "./CourierOptions"
import PaymentOptions from "./PaymentOptions"

import { AppContext } from "../../../context/AppContext"
import { useShippingOptionsImages } from "../hooks/useShippingOptionsImages"

import { accumulatePrice } from "../services/computations"
import regularOrderCourierOptions from "../utils/regularOrderCourierOptions.json"
import filterCourierOptions from "./utils/filterCourierOptions"

const DispensingChannelOptions = ({
  values,
  isNationwide,
  setFieldValue,
  configurationChannels,
  module,
}) => {
  const { state, dispatch } = useContext(AppContext)
  const { epharmacy } = state
  const { medicines, patients } = epharmacy

  const shippingOptionsImages = useShippingOptionsImages()
  const courierOptions = regularOrderCourierOptions
  const province = epharmacy?.deliveryAddress?.province.value
  const city = epharmacy?.deliveryAddress?.city.value
  const barangay = epharmacy?.deliveryAddress?.barangay.value
  const medicineSKUs =
    patients?.length > 0
      ? patients
          ?.reduce(
            (allMedicines, currentPatient) =>
              allMedicines.concat(currentPatient?.medicines),
            []
          )
          ?.map((medicine) => medicine?.productTitle)
      : medicines?.map((medicine) => medicine?.productTitle)
  const filteredCourierOptions = filterCourierOptions({
    isNationwide: isNationwide,
    medicineSKUs: medicineSKUs,
    province: province,
    configurationChannels: configurationChannels,
    city: city,
    courierOptions: courierOptions,
  })
  const filteredCourierOptionNames = filteredCourierOptions.map(
    (courierOption) => courierOption.name
  )
  const hasMedGrocerOption = filteredCourierOptionNames?.includes("medgrocer")
  const cardInfo = province == "Metro Manila" ? "ncrInfo" : "nonNcrInfo"

  useEffect(() => {
    let filteredCourierOptionLabels = filteredCourierOptions.map(
      (courierOption) => courierOption.label
    )
    if (!isEqual(filteredCourierOptionLabels, epharmacy?.dispensingChannels)) {
      dispatch({
        type: "SAVE_DISPENSING_CHANNELS",
        payload: [...filteredCourierOptionLabels],
      })
    }
    if (hasMedGrocerOption !== epharmacy?.hasMedGrocerOption)
      dispatch({
        type: "SAVE_HAS_MEDGROCER_OPTION",
        payload: {
          hasMedGrocerOption,
        },
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredCourierOptions])

  // get the subtotal for the form field validation
  useEffect(() => {
    setFieldValue("subtotal", accumulatePrice(values?.medicines))
  }, [])

  return (
    <div className="mb-2">
      <CourierUserAddress province={province} city={city} barangay={barangay} />
      <CourierOptions
        values={values}
        setFieldValue={setFieldValue}
        shippingOptionsImages={shippingOptionsImages}
        cardInfo={cardInfo}
        filteredCourierOptions={filteredCourierOptions}
      />
      <PaymentOptions
        values={values}
        setFieldValue={setFieldValue}
        shippingOptionsImages={shippingOptionsImages}
        module={module}
        isNationwide={isNationwide}
        courierOption={values.courierOption}
      />
    </div>
  )
}

export default DispensingChannelOptions
