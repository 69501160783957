import React from "react"

import FormInput from "../../Elements/Form/FormInput"
import Message from "../../Elements/Message"

const PaymentOptionFollowUpField = ({ paymentOptionFollowUpField }) => {
  switch (paymentOptionFollowUpField?.type) {
    case "message":
      return (
        <Message color="warning" className="mx-1 mt-2 mx-0-mobile">
          <p className="is-size-6">{paymentOptionFollowUpField?.value}</p>
        </Message>
      )

    case "input":
      return (
        <FormInput
          type={paymentOptionFollowUpField?.subtype}
          name={paymentOptionFollowUpField?.name}
          label={paymentOptionFollowUpField?.label}
          maxLength={paymentOptionFollowUpField?.maxLength}
          addonsLeft={paymentOptionFollowUpField?.addonsLeft}
          hasAddons={paymentOptionFollowUpField?.hasAddons}
          isRequired={true}
          hideOptional={true}
        />
      )

    default:
      return null
  }
}

export default PaymentOptionFollowUpField
