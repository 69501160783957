import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"

import Message from "../Elements/Message"
import Section from "../Elements/Section"
import ActionButtons from "../Elements/ActionButtons"
import OrderDetails from "../OrderDetails/OrderDetails"
import InvalidFormMessage from "../Elements/InvalidFormMessage"
import DeliveryScheduleSection from "./PaymentDetails/DeliveryScheduleSection"
import DispensingChannelOptionsSection from "./PaymentDetails/DispensingChannelOptionsSection"
import FeedbackSection from "./PaymentDetails/FeedbackSection"

import { isFutureDate } from "./services/date"
import { AppContext } from "../../context/AppContext"
import { isObjectEmpty } from "../../services/general"
import { validationSchema } from "./utils/dispensingChannelsSchema"
import { getContextFromSession } from "../../context/services/context"

const PaymentDetails = ({ backRoute, baseRoute, module }) => {
  const { state, dispatch } = useContext(AppContext)
  let formValues = getContextFromSession()
  if (isObjectEmpty(formValues)) formValues = state
  const isNationwide =
    state?.epharmacy?.deliveryAddress?.province?.value !== "Metro Manila"

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isSpecialOrder = module === "Special Order"

  const handleSubmit = async (values, { setErrors }) => {
    let preferredDeliveryDate = values?.preferredDeliveryDate
    preferredDeliveryDate = `${preferredDeliveryDate?.month?.value} ${preferredDeliveryDate?.date?.value} ${preferredDeliveryDate?.year?.value}`

    if (!isFutureDate({ date: preferredDeliveryDate })) {
      setErrors({
        preferredDeliveryDate: {
          month: { value: "Please enter a valid delivery date." },
          date: { value: "Please enter a valid delivery date." },
        },
      })
      return values
    }

    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        proofOfPayment: "",
        epharmacy: { ...state.epharmacy, ...values },
      },
    })

    navigate(`${baseRoute}/summary`)
  }

  let configurationChannels = []

  return (
    <Formik
      initialValues={{
        ...state?.epharmacy,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema({ isNationwide, isSpecialOrder })}
    >
      {({ values, setFieldValue, handleChange, submitCount, isValid }) => {
        let preferredDeliveryDate = values?.preferredDeliveryDate
        preferredDeliveryDate = `${preferredDeliveryDate?.month?.value} ${preferredDeliveryDate?.date?.value} ${preferredDeliveryDate?.year?.value}`
        let isInvalidDeliveryDate = !isFutureDate({
          date: preferredDeliveryDate,
        })

        return (
          <Form>
            <DeliveryScheduleSection
              isNationwide={isNationwide}
              setFieldValue={setFieldValue}
              values={values}
              handleChange={handleChange}
            />
            <DispensingChannelOptionsSection
              values={values}
              isNationwide={isNationwide}
              setFieldValue={setFieldValue}
              configurationChannels={configurationChannels}
              module={module}
            />
            <Section title="Order Details">
              <OrderDetails
                setFieldValue={setFieldValue}
                values={values}
                dispensingChannels={state?.epharmacy?.dispensingChannels}
              />
            </Section>
            <Message color="warning" className="mx-1 mt-2 mx-0-mobile">
              <p className="has-text-weight-bold is-size-6 mb-1">
                Your preferred subdistributor may charge additional fees for
                deliveries outside of their serviceable areas. We'll reach out
                to you again if additional charges will be applied.
              </p>
              <p className="is-size-6">
                For orders outside Metro Manila, please allow several days of
                lead time (may take at least 3 to 5 days depending on the chosen
                subdistributor).
              </p>
            </Message>
            {!isSpecialOrder && (
              <FeedbackSection setFieldValue={setFieldValue} values={values} />
            )}
            {!isValid && submitCount > 0 && (
              <InvalidFormMessage
                color="danger"
                name={
                  isInvalidDeliveryDate
                    ? "invalidDeliveryDate"
                    : "missingRequiredFields"
                }
                className="mx-1 mx-0-mobile"
              />
            )}
            <ActionButtons
              submit={{ label: `Next: Summary` }}
              back={{ label: "Back", link: backRoute || "/epharmacy/checkout" }}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export default PaymentDetails
