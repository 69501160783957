import React, { Fragment, useEffect } from "react"
import FormRadio from "../Elements/Form/FormRadio"
import dispensingChannel from "./utils/dispensingChannel.json"

const OrderDetails = ({ values, setFieldValue, dispensingChannels = [] }) => {
  let formFields = [
    {
      type: "radio",
      options: dispensingChannel,
      label:
        "If your preferred dispensing channel encounters challenges delivering your order, do you want to:",
      name: "dispensingChannel",
      isFollowUpQuestion: false,
    },
  ]

  return (
    <Fragment>
      <FormRadio
        name="dispensingChannel"
        title="If your preferred dispensing channel encounters challenges delivering your order, do you want to:"
        options={dispensingChannel}
        value={values?.dispensingChannel}
        isRequired
        formFields={formFields}
        formValues={values}
      />
    </Fragment>
  )
}

export default OrderDetails
